.shadow-effect {
	background: #fff;
	padding: 20px;
	text-align: center;
	border: 1px solid #d4b66b;
	border-radius: 5px;
	box-shadow: 2px 2px 16px rgba(92, 92, 92, 0.15);
}
#shadow-effect p {
	font-family: inherit;
	font-size: 17px;
	line-height: 1.5;
	margin: 0 0 17px 0;
	font-weight: 300;
}
.img-circle {
	border-radius: 50%;
	vertical-align: middle;
	max-width: 90px;
	min-height: 90px;
	transform-style: preserve-3d;
	margin: 0 auto;
}
#customer-testimonoals {
	margin-top: 0;
}
.testimonial-name {
	margin: -17px auto 0;
	padding-top: 9px;
	text-align: center;
	color: #d4b66b;
}
#customer-testimonoals .item {
	text-align: center;
	padding: 15px;
	margin-bottom: 0px;
	opacity: 0.7;
	filter: blur(1px);
	transform: scale3d(0.8, 0.8, 1);
	transition: all 0.3s ease-in-out;
}
#customer-testimonoals .owl-item.active.center .item {
	opacity: 1;
	transform: scale3d(1, 1, 1);
	filter: none;
}
.owl-carousel .owl-dots {
	display: inline-block;
	width: 100%;
	text-align: center;
}

.owl-carousel .owl-dots .owl-dot {
	display: inline-block;
}
.owl-carousel .owl-dots .owl-dot span {
	background: #d4b66b82;
	display: inline-block;
	height: 20px;
	width: 20px;
	border-radius: 50%;
	transform: translate3d(0px, -50%, 0px) scale(0.3);
	transform-origin: 50% 50% 0;
	transition: all 250ms ease-out 0s;
}

.arrow {
	position: absolute;
	border-radius: 3px;
	font-size: 26px;
	top: 50%;
	animation: 2s;
}

.arrow:hover {
	transform: scale(1.2);
	animation: 2s;
}

.next {
	right: 10px;
	font-size: 30px;
}

.prev {
	left: 10px;
	font-size: 30px;
}

.owl-dots .owl-dot.active span,
.owl-dots .owl-dot:hover span {
	background: #d4b66b;
	transform: translate3d(0px, -50%, 0px) scale(0.4);
}
