.shadow-effect-courses-carousel {
	background: #fff;
	text-align: center;
	border: 1px solid #d4b66b;
	border-radius: 5px;
	box-shadow: 2px 2px 16px rgba(92, 92, 92, 0.15);
	overflow: hidden
}
#shadow-effect-courses-carousel p {
	font-family: inherit;
	font-size: 17px;
	line-height: 1.5;
	margin: 0 0 17px 0;
	font-weight: 300;
}

.course-name {
	font-size: 24px;
	text-align: left;
	margin-top: 0;
}

.img-course {
	vertical-align: middle;
	object-fit: cover;
	width: 100%;
	max-height: 140px;
	transform-style: preserve-3d;
	margin: 0 auto;
	opacity: 0.7;
}
#highlight-courses {
	margin-top: 0;
}

.item-content{
	padding: 16px;
	letter-spacing: 0.5px;
	word-spacing: 0.3px;
	text-align: left;
}

.item-content-headline {
	color: black;
	margin-bottom: 4px;
	margin-top: 0;
}

.item-content {
	font-size: 16px;
}

.course-name {
	margin: -17px auto 0;
	text-align: left;
	padding-top: 9px;
	color: #d4b66b;
	font-family: 'Lato';

}
#highlight-courses .item {
	text-align: center;
	margin-bottom: 0px;
	/* opacity: 0.7;
	filter: blur(1px); */
	-webkit-mask-image: linear-gradient(0.5turn, #d4b66b, transparent);
	mask-image: linear-gradient(#d4b66b, transparent);
	-webkit-filter: blur(1px);
	transform: scale3d(0.8, 0.8, 1);
	transition: all 0.3s ease-in-out;
}
#highlight-courses .owl-item.active.center .item {
	opacity: 1;
	-webkit-mask: none;
	-webkit-filter: none;
	transform: scale3d(1, 1, 1);
	filter: none;
}
.owl-carousel .owl-dots {
	display: inline-block;
	width: 100%;
	text-align: center;
	margin-top: 20px;
}

.owl-carousel .owl-dots .owl-dot {
	display: inline-block;
}
.owl-carousel .owl-dots .owl-dot span {
	background: #d4b66b82;
	display: inline-block;
	height: 20px;
	width: 20px;
	border-radius: 50%;
	transform: translate3d(0px, -50%, 0px) scale(0.3);
	transform-origin: 50% 50% 0;
	transition: all 250ms ease-out 0s;
}

.arrowCoursesCarousel {
	position: absolute;
	top: 50%;
	animation: 2s;
}

.arrowCoursesCarousel:hover {
	transform: scale(1.2);
	animation: 2s;
}

.nextCoursesCarousel {
	right: 0px;
	font-size: 30px;
}

.prevCoursesCarousel {
	left: 00px;
	font-size: 30px;
}

.owl-dots .owl-dot.active span,
.owl-dots .owl-dot:hover span {
	background: #d4b66b;
	transform: translate3d(0px, -50%, 0px) scale(0.4);
}
