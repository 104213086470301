@font-face {
	font-family: "Ingeborg";
	src: url("./assets/fonts/Ingeborg-Regular.otf") format("otf");
}
@font-face {
	font-family: "Lato";
	src: url("./assets/fonts/Lato/Lato-Regular.woff2") format("woff2");
}

:root {
	--bs-font-sans-serif: Lato;
	--bs-gradient: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0.15),
		rgba(255, 255, 255, 0)
	);
	--bs-body-font-family: var(--bs-font-sans-serif);
	--bs-body-font-size: 18px;
	--bs-body-font-weight: 400;
	--bs-body-line-height: 1.5;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 #d4b66b7c;
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 5px;
		padding-left: 5px;
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0px 5px #d4b66b13;
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 5px;
		padding-left: 5px;
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 #d4b66b0e;
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 5px;
		padding-left: 5px;
	}
}

body {
	margin: 0;
	font-family: Ingeborg;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: visible;
}

section {
	padding-bottom: 40px;
}

.gray-background {
	background-color: #f6f6f6;
}

code {
	font-family: Ingeborg;
}

.carouselImg {
	margin-left: auto;
	margin-right: auto;
	object-fit: cover;
	object-position: center;
	width: auto;
	height: calc(100vh - 40px);
}

.cardImg {
	object-fit: cover;
}

.card2 {
	border: 2px solid #d4b66b;
	text-align: center;
	text-transform: uppercase;
	color: #d4b66b;
	background-color: #d4b66b0a;
	overflow: hidden;
	font-family: Lato;
	margin-left: auto;
	margin-right: auto;
}

.card_title {
	position: absolute;
	text-decoration: none;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.card2:hover {
	transform: scale(1.02);
	background-color: #d4b66b;
	color: #fff;
	transition: 500ms;
}

.show-more {
	color: black;
	text-decoration: underline;
	cursor: pointer;
}

.headerLogo {
	width: 150px;
	height: auto;
}

.headerLogoRotation {
	animation: spin 2s infinite;
	animation-direction: reverse;
	animation-duration: 2s;
	animation-timing-function: ease-in;
}

@media (min-width: 300px) {
	.card2 {
		height: 95px;
		width: 95px;
		font-size: 18px;
		border-radius: 95px;
	}
	.cardImg {
		height: 95px;
	}
}

@media (min-width: 390px) {
	.card2 {
		height: 115px;
		width: 115px;
		font-size: 18px;
		border-radius: 115px;
	}
	.cardImg {
		height: 115px;
	}
}

@media (min-width: 480px) {
	.card2 {
		height: 125px;
		width: 125px;
		border-radius: 125px;
		font-size: 24px;
	}
	.cardImg {
		height: 125px;
	}
}

@media (min-width: 576px) {
	.card2 {
		height: 135px;
		width: 135px;
		border-radius: 135px;
		font-size: 28px;
	}
	.cardImg {
		height: 135px;
	}
}

@media (min-width: 768px) {
	.card2 {
		height: 145px;
		width: 145px;
		border-radius: 145px;
		font-size: 32px;
	}
	.cardImg {
		height: 145px;
	}
}

@media (min-width: 992px) {
	.card2 {
		height: 165px;
		width: 165px;
		border-radius: 165px;
		font-size: 32px;
	}
	.cardImg {
		height: 165px;
	}
}

@media (min-width: 1200px) {
	.card2 {
		height: 165px;
		width: 165px;
		border-radius: 165px;
		font-size: 32px;
	}
	.cardImg {
		height: 165px;
	}
}

.outer-container {
	margin: auto;
	display: flex;
	border: 2px solid #d4b66b;
	border-radius: 10px;
	overflow: hidden;
}

.outer-container > div {
	flex: 1;
	padding: 20px;
}

.outer-container .vertical-carousel-wrapper {
	display: flex;
	flex-direction: column;
}

.outer-container .vertical-carousel-wrapper div,
.vertical-carousel-item {
	font-weight: 300;
	font-size: 16px;
	color: #d4b66b;
	line-height: 1.3;
}

.outer-container .content {
	background: white;
}

.vertical-carousel {
	height: 100%;
	display: flex;
	flex: 5;
	align-items: center;
}

.vertical-carousel-button {
	display: flex;
	align-self: center;
	padding: 10px;
	max-width: 50px;
	border-radius: 30px;
	border: none;
	background-color: transparent;
}

.vertical-carousel-button:hover {
	transform: scale(1.2);
}

.vertical-carousel-button svg {
	height: 18px;
	width: 18px;
}
.vertical-carousel-button path {
	fill: #d4b66b;
}

.slides {
	align-self: flex-start;
	flex: 1;
	width: 100%;
	overflow: hidden;
}

.vertical-carousel-inner {
	position: relative;
	max-height: 250px;
	height: 250px;
}

.vertical-carousel-inner::before,
.vertical-carousel-inner::after {
	content: "";
	z-index: 1;
	position: absolute;
	pointer-events: none;
}

.vertical-carousel-inner::before {
	top: 0;
}

.vertical-carousel-inner::after {
	bottom: 0;
}

.vertical-carousel-item {
	position: absolute;
	background: none;
	border: none;
	padding: 5px;
	margin: 0;
	opacity: 0;
	top: 112px;
	transition: transform 0.4s ease, opacity 0.4s ease;
	border: 1px solid;
	border-radius: 10px;
	width: 100%;
}

.vertical-carousel-item.visible {
	opacity: 1;
}

.courses-wrapper {
	max-height: 50vh;
	overflow-y: scroll;
}

.arrow-down {
	margin-left: auto;
	margin-right: auto;
	flex-direction: baseline;
	animation: pulse 3s infinite;
}

.sub-title {
	color: black;
	font-size: 24px;
	margin-bottom: 5px;
	margin-top: 0;
}

.podcast-series-container {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 20px;
}

.podcastSeriesImg {
	max-width: 25%;
	cursor: pointer;
	filter: opacity(0.8);
	border-radius: 15px;
	object-fit: cover;
	vertical-align: bottom;
	padding: 5px;
}

.podcastSeriesImg:hover {
	filter: opacity(1);
	/* transform: scale(1.02); */
	transition: transform 0.8s ease;
}

.podcast-buttons {
	color: #d4b66b;
	text-align: center;
	line-height: 40px;
	width: 42px;
	height: 42px;
	background: rgb(255, 255, 255);
	border: 1px solid #d4b66b;
	border-radius: 20px;
}
.podcast-buttons:hover {
	color: #fff;
	background: #d4b66b;
	border: 1px solid #d4b66b;
	transition: 500ms;
}

.caption-buttons {
	color: #d4b66b;
	text-align: center;
	background: rgb(255, 255, 255);
	border: 1px solid #d4b66b;
	border-radius: 5;
	font-size: 18px;
	width: 100%;
	position: relative;
}
.caption-buttons:hover {
	color: #fff;
	background: #d4b66b;
	border: 1px solid #d4b66b;
	transition: 500ms;
}

.blog-series-container {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 20px;
}

.blog-wrapper {
	position: relative;
	width: 50%;
	cursor: pointer;
}

.blogSeriesImg {
	width: 100%;
	cursor: pointer;
	border-radius: 15px;
	object-fit: cover;
	vertical-align: bottom;
	padding: 5px;
	filter: opacity(0.8);
}

.blogSeriesText {
	color: white;
	text-align: center;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 13;
	font-size: 14px;
}

.blogSeriesTextBackground {
	text-align: center;
	position: absolute;
	left: 50%;
	top: 50%;
	height: 95%;
	width: 95%;
	z-index: 12;
	transform: translate(-50%, -50%);
	/* background: rgba(0, 0, 0, 0)
		radial-gradient(
			circle,
			rgba(212, 182, 107, 0.6) 0px,
			rgba(212, 182, 107, 0.2) 30%,
			rgba(212, 182, 107, 0) 80%
		); */
	background: rgba(0, 0, 0, 0)
		radial-gradient(
			circle,
			rgba(0, 0, 0, 0.6) 0px,
			rgba(0, 0, 0, 0.2) 30%,
			rgba(0, 0, 0, 0) 80%
		);
}


/* .blogSeriesImg:hover {
	filter: opacity(1);
} */

.latoHeader {
	margin-bottom: 20px;
	font-size: 24px;
	font-weight: 500;
	color: black;
	font-family: Lato;
}

.ingeborgHeader {
	font-size: 24px;
	font-weight: 500;
	text-transform: uppercase;
	font-family: Ingeborg;
}

.cvZahlen {
	color: black;
	font-size: 18px;
	font-family: Lato;
	padding-left: 0;
	padding-right: 0;
}

.cvBeschreibung {
	font-size: 18px;
	font-family: Lato;
	font-weight: 200;
}

.mail-chimp-background {
	position: relative;
	color: white;
	/* background-color: rgba(0, 0, 0, 0.6) */
	background: rgba(0, 0, 0, 0)
		linear-gradient(to top, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0.6) 100%)
		repeat 0 0;
}

.mail-chimp-background::before {
	content: "";
	background-image: url(./images/coline-hasle-162XG_FsXcI-unsplash.jpeg);
	background-size: cover;
	background-position: right;
	background-repeat: no-repeat;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	filter: opacity(0.4);
}

.mail-chimp-background-inside {
	font-family: Lato;
	position: relative;
	text-shadow: 1px 1px 0.5px rgba(26, 25, 25, 0.171);
}

.telegram-banner-background {
	position: relative;
	color: white;
	/* background-color: rgba(0, 0, 0, 0.6) */
	background: rgba(0, 0, 0, 0)
		linear-gradient(to top, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0.6) 100%)
		repeat 0 0;
}

.telegram-banner-background::before {
	content: "";
	/* background-image: url(./images/Blumenwiese.jpeg); */
	background-image: url(./images/BlühenderBaum.jpeg);
	background-size: cover;
	background-position: right;
	background-repeat: no-repeat;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	filter: opacity(0.4);
}

.telegram-banner-background-inside {
	font-family: Lato;
	position: relative;
	text-shadow: 1px 1px 0.5px rgba(26, 25, 25, 0.171);
}

.logo-path-to-power {
	justify-content: center;
	margin: auto;
	display: block;
	width: 40%;
	height: 40%;
}

.img-path-to-power {
	justify-content: center;
	margin: auto;
	display: block;
	width: 50%;
	height: 50%;
}

.text-path-to-power {
	margin-bottom: 0;
	margin-top: 0;
	font-size: 24px;
	font-weight: 500;
	color: black;
	font-family: Lato;
}

.button-path-to-power {
	margin-top: 10px;
	width: 100%;
	height: 60%;
	font-family: lato;
}

.sub-titel-path-to-power {
	font-size: 14px;
	margin-bottom: 18px;
	text-align: center;
	color: black;
}

.sub-titel-path-to-power-card {
	font-size: 14px;
	margin-bottom: 0;
	text-align: center;
	color: black;
}

.rotateZeichen {
	justify-content: center;
	margin: auto;
	display: block;
	width: 10%;
	height: 10%;
	animation: spin 2s infinite;
	z-index: 1;
	animation-direction: reverse;
	animation-duration: 2s;
	animation-timing-function: ease-in;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.loading-Center {
	text-align: center;
	animation-name: animation_blink;
	animation-timing-function: ease-in;
	animation-duration: 1s;
	animation-iteration-count: infinite;
}

@keyframes animation_blink {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.1;
	}
	100% {
		opacity: 1;
	}
}

.buttonSection1:hover {
	/* transform: translateY(-5px); */
	transform: scale(1.02);
	background-color: #d4b66b;
	color: #fff;
	transition: 500ms;
}

.category-button {
	font-family: Lato;
}

.contact-button {
	position: fixed;
	bottom: 250px;
	right: -15px;
	transform: rotate(270deg);
	z-index: 100;
	height: 40px;
}

.cookie-button {
	position: fixed;
	text-align: center;
	bottom: 4px;
	right: 4px;
	width: 40px;
	height: 40px;
	border: 1px solid;
	border-radius: 50%;
	cursor: pointer;
	/* transform: rotate(270deg); */
	line-height: 40px;
	z-index: 100;
	display: flex !important;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.scroll-to-top-button {
	position: fixed;
	text-align: center;
	bottom: 180px;
	right: 4px;
	width: 40px;
	height: 40px;
	border: 1px solid;
	border-radius: 5px;
	background-color: #fff;
	cursor: pointer;
	/* transform: rotate(270deg); */
	line-height: 40px;
	z-index: 100;
}

.scroll-to-top-button:hover {
	background-color: #d4b66b;
	color: #fff;
}

.socialMedia-Background {
	background-color: #d4b66b7c;
	padding-top: 20px;
	padding-bottom: 20px;
	text-align: center;
}

.socialMedia-background-inside {
	text-align: center;
	color: white;
	font-family: Lato;
	position: relative;
	text-shadow: 1px 1px 0.5px rgba(26, 25, 25, 0.171);
}

.menu {
	position: relative;
	display: flex;
	justify-content: right;
	align-items: center;
	margin: 10px 0;
	flex-wrap: wrap;
	padding-left: 0;
}

.menu li {
	list-style: none;
}

.menu li a {
	font-size: 14px;
	color: #d4b66b;
	opacity: 0.75;
	margin: 0 15px;
	text-decoration: none;
	display: inline-block;
}
.menu li a:hover {
	opacity: 1;
}
footer p {
	color: #d4b66b;
	text-align: center;
	margin-top: 15px;
	font-size: 14px;
}

.social {
	font-size: 40px;
	position: relative;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	padding-left: 0;
}
.social li {
	list-style: none;
}
.social li a {
	color: #fff;
	display: inline-block;
	transition: 0.5s;
	margin-right: 30px;
}
.social li a:hover {
	transform: translateY(-10px);
}

.nav-social-button {
	color: #d4b66b;
	font-size: 22px;
}

.nav-social-button:hover {
	color: #d4b66ba8;
}

.plus-button-only {
	width: 50px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}

.minus-button {
	width: 50px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.plus-button {
	width: 50px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}

@media (min-width: 576px) {
	.podcastSeriesImg {
		max-width: 25%;
	}
	.logo-path-to-power {
		width: 30%;
		height: 30%;
	}
	.img-path-to-power {
		width: 35%;
		height: 35%;
	}
}

@media (min-width: 768px) {
	.podcastSeriesImg {
		max-width: 25%;
	}
	.logo-path-to-power {
		width: 25%;
		height: 25%;
	}
	.img-path-to-power {
		width: 30%;
		height: 30%;
	}
	.blog-wrapper {
		width: 25%;
	}
}

@media (min-width: 992px) {
	.podcastSeriesImg {
		max-width: 20%;
	}
	.logo-path-to-power {
		width: 15%;
		height: 15%;
	}
	.img-path-to-power {
		width: 25%;
		height: 25%;
	}
}

@media (min-width: 1200px) {
	.podcastSeriesImg {
		max-width: 20%;
	}
	.headerLogo {
		width: 200px;
		height: 200px;
	}
	.podcastSeriesImg {
		max-width: 20%;
	}
	.logo-path-to-power {
		width: 10%;
		height: 10%;
	}
	.img-path-to-power {
		width: 15%;
		height: 15%;
	}
}

@media (min-width: 1400px) {
	.podcastSeriesImg {
		max-width: 20%;
	}
}

@media (min-width: 2000px) {
	.podcastSeriesImg {
		max-width: 20%;
	}
}


.video-responsive {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.video-responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-responsive div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.course-card {
	height: 100%;
	transition: all 0.3s ease;
}

.course-card:hover {
	transform: translateY(-2px);
	box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.course-card .card-img-top {
	height: 200px; /* Adjust this value as needed */
	object-fit: cover;
}

.course-subtitle {
	font-size: 0.9rem;
	color: #666;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	line-height: 1.4;
}

.course-subtitle p {
	margin: 0;
}